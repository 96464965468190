import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import BottomMenu from "../../bottombar";
import { CustomGrid } from "../../../Styles/style";
import SectionHeading from "../../SectionHeading";
import CustomButton from "../../CustomBtn";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  z-index: 0;
  background: linear-gradient(to bottom, #9747fd, #4c247f);
  padding: 50px 0 20px !important;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const Footer = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="footer"> 
      <Container>
        <CustomGrid container jc="space-between" p="0 0 20px" m="0 0 20px" g="20px 0" sx={{borderBottom:"1px solid #ffffff86"}}>
          <CustomGrid item xs={12} sm={7} g="20px" fd="column"  sx={{
              alignItems: { xs: "center !important", sm: "start !important" },
            }}>
            <SectionHeading fontFamily="PoppinsM" variant="h5">
              Wanna Keep Track Of Nyra Swap's <br /> Updates? Join Our Community.
            </SectionHeading>
            <Paragraph variant="body2">
            Email: info@nyraswap.com
            </Paragraph>
          </CustomGrid>
          <CustomGrid
            item
            xs={12}
            sm={5}
            sx={{
              justifyContent: { xs: "center !important", sm: "end !important" },
            }}
          >
            <CustomButton
                  href="https://t.me/nyraswaponsol" target="blank"
            bgc="#19FB9B"
            hbgc="transparent"
            c="#000"
            hc="#19FB9B"
            b="1px solid transparent"
            hb="#19FB9B"
            sx={{
              boxShadow: "inset 0px 3px 6px #ffffff68",
            }}>Go To Telegram</CustomButton>
          </CustomGrid>
        </CustomGrid>
      </Container>
      <BottomMenu />
    </ContinerWraper>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import Tokenomics from "../../Components/Sections/S3-Tokenomics";
import Footer from "../../Components/Sections/S5-Footer";
import Community from "../../Components/Sections/S4-Community";
import stars from "../../../assets/images/stars.png";



const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>

      <img src={stars}  alt="" style={{
        position:"fixed",
        width:"100%",
        height:"100vh",
        objectFit:"cover",
        animation:"blink 3s ease infinite"
      }}/>
      <img src={stars}  alt="" style={{
        position:"fixed",
        width:"100%",
        height:"100vh",
        objectFit:"cover",
        transform:"scaleX(-1)",
        animation:"blink1 2s ease infinite"
      }}/>
      <HeroSection />
      <AboutSection />
      <Tokenomics />
      <Community/>
      <Footer />
    </PageWrapper>
  );
};

export default MainPage;

import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import SectionHeading from "../../SectionHeading";
import Paragraph from "../../Paragraph";
import x from "../../../../assets/images/X-Icon.png";
import tg from "../../../../assets/images/telegram-plane.png";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height:  auto;
  width: 100%;
  padding: 50px 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height:  autoauto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height:  auto;
  width: 100%;
  padding: 50px 50px !important;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 50px 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const Community = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="roadmap">
        <InnerContinerWraper>
          <CustomGrid container>
            <CustomGrid item xs={12} p="20px 0" data-aos="zoom-in"> 
              <SectionHeading variant="h4">Join Our Community</SectionHeading>
            </CustomGrid>
            <CustomGrid container item xs={12} sm={6} p="30px" jc="end" data-aos="fade-up">
              <CustomGrid
                item
                xs={12}
                sm={10}                md={6}
                ai="start"
                g="10px"
                p="20px"
                fd="column"
                jc="end"
                sx={{
                  position:"relative",
                  minHeight:"150px",
                  background: "#191919",
                  borderRadius: "5px",
                  boxShadow: " 0px 10px 50px #9d53fe61",
                }}
              >
                <img src={x} alt="x" style={{
                  width:"40px",
                  position:"absolute",
                  top:"-15px",
                  left:"20px"
                }}/>
                <Paragraph>Follow us and be first for all the things</Paragraph>
                <CustomButton
                href="https://twitter.com/nyraswaponsol" target="blank"
                bgc="linear-gradient(to bottom,#9747FD,#4C247F)"
                hbgc="transparent"
                  c="#fff"
                  hc="#9747FD"
                  b="1px solid transparent"
                  hb="#9747FD"
                  sx={{
                    boxShadow: "inset 0px 3px 6px #ffffff68",
                  }}
                >
                  Follow
                </CustomButton>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid container item xs={12} sm={6} p="30px" jc="start"  data-aos="fade-up">
              <CustomGrid
                item
                xs={12}
                sm={10}                md={6}
                ai="start"
                g="10px"
                p="20px"
                fd="column"
                jc="end"
                sx={{
                  position:"relative",
                  minHeight:"150px",
                  background: "#191919",
                  borderRadius: "5px",
                  boxShadow: " 0px 10px 50px #9d53fe61",
                }}
              >
                <img src={tg} alt="tg" style={{
                  width:"45px",
                  position:"absolute",
                  top:"-15px",
                  left:"20px"
                }}/>
                <Paragraph>
                  Engage and initiate proposals for governance
                </Paragraph>
                <CustomButton
                  href="https://t.me/nyraswaponsol" target="blank"
                  bgc="linear-gradient(to bottom,#9747FD,#4C247F)"
                  hbgc="transparent"
                  c="#fff"
                  hc="#9747FD"
                  b="1px solid transparent"
                  hb="#9747FD"
                  sx={{
                    boxShadow: "inset 0px 3px 6px #ffffff68",
                  }}
                >
                  Join
                </CustomButton>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Community;

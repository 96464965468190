import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import LockerMenu from "../../LockerMenu";
import { CustomGrid } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import SectionHeading from "../../SectionHeading";
import CustomButton from "../../CustomBtn";
import Bg from "../../../../assets/images/herobg.png";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  background-image: url(${Bg});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  @media (max-width: 899px) {
    background-position: left;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HeroSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
        <LockerMenu />
        <InnerContinerWraper>
          <CustomGrid container p="6% 0" ai="start" jc="start">
            <CustomGrid
              container
              item
              xs={12}
              sm={8}
              md={6}
              jc="start"
              fd="column"
              ai="start"
            >
              <SectionHeading data-aos="zoom-in" color="#9747FD">Low Cost</SectionHeading>
              <SectionHeading data-aos="zoom-in" color="#19FA9C">Stable Swaps</SectionHeading>
              <CustomGrid item xs={12} sm={10} md={9}>
                <Paragraph padding="10px 0" data-aos="fade-up">
                  Welcome to Nyra Swap, your gateway to low-cost stable swaps.
                  Unlock seamless and predictable transactions with our
                  innovative platform, redefining the future of decentralized
                  finance.
                </Paragraph>
              </CustomGrid>
              <div
                // item
                // xs={12}
                data-aos="fade-up"
                style={{
                  display: "flex",
                  gap: "10px",
                  paddingTop: "10px",
                }}
              >
                {/* <CustomButton
                  bgc="linear-gradient(to bottom,#9747FD,#4C247F)"
                  hbgc="transparent"
                  c="#fff"
                  hc="#9747FD"
                  b="1px solid transparent"
                  hb="#9747FD"
                  sx={{
                    boxShadow: "inset 0px 3px 6px #ffffff68",
                  }}
                >
                  Buy $NYRA
                </CustomButton> */}
                <CustomButton href="https://dexscreener.com/solana/8t66M9CNBZttYRJg2kX8b6FoWwPrPSFv9hwL9WJdGec6" target="blank">View Chart</CustomButton>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;

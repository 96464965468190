import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import SectionHeading from "../../SectionHeading";
import catImage from "../../../../assets/images/cat2Image.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
  @media (min-width: 1440px) {
    min-height: 70vh;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
  @media (min-width: 1440px) {
    min-height: 70vh;
  }
`;

const Tokenomics = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper>
          <CustomGrid
            container
            p="40px"
            sx={{
              position: "relative",
              background: "linear-gradient(to bottom,#9747FD,#4C247F)",
              borderRadius: "5px",
            }}
          >
            <CustomGrid container item xs={12} md={5} jc="start" g="20px">
              <SectionHeading variant="h4">Nyra Token</SectionHeading>
              <Paragraph variant="span" padding="20px 0 0">
                As the official token for Nyra Swap, $Nyra tokens enable users
                to partake in proof of stake governance.
              </Paragraph>
              <CustomGrid
                container
                item
                xs={12}
                sm={5.7}
                sx={{
                  background: "#592A95",
                  boxShadow: " 0px 3px 6px #00000061",
                  borderRadius: "5px",
                }}
                p="10px"
              >
                <CustomGrid item xs={12} jc="start">
                  <Paragraph>Name</Paragraph>
                </CustomGrid>
                <CustomGrid item xs={12} jc="end">
                  <Paragraph variant="h5" padding="20px 0 0">
                    NYRA Swap
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={5.7}
                sx={{
                  background: "#592A95",
                  boxShadow: " 0px 3px 6px #00000061",
                  borderRadius: "5px",
                }}
                p="10px"
              >
                <CustomGrid item xs={12} jc="start">
                  <Paragraph>Ticker</Paragraph>
                </CustomGrid>
                <CustomGrid item xs={12} jc="end">
                  <Paragraph variant="h5" padding="20px 0 0">
                    $NYRA
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={5.7}
                sx={{
                  background: "#592A95",
                  boxShadow: " 0px 3px 6px #00000061",
                  borderRadius: "5px",
                }}
                p="10px"
              >
                <CustomGrid item xs={12} jc="start">
                  <Paragraph>Liquidity</Paragraph>
                </CustomGrid>
                <CustomGrid item xs={12} jc="end">
                  <Paragraph variant="h5" padding="20px 0 0">
                    Burnt
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={5.7}
                sx={{
                  background: "#592A95",
                  boxShadow: " 0px 3px 6px #00000061",
                  borderRadius: "5px",
                }}
                p="10px"
              >
                <CustomGrid item xs={12} jc="start">
                  <Paragraph>Contract</Paragraph>
                </CustomGrid>
                <CustomGrid item xs={12} jc="end">
                  <Paragraph variant="h5" padding="20px 0 0">
                    Revoked
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid
                item
                xs={12}
                jc="start"
                sx={{
                  background: "#19FB9B",
                  boxShadow: " 0px 3px 6px #00000061",
                  borderRadius: "5px",
                }}
                p="10px"
              >
                <Paragraph color="#000" sx={{ wordBreak: "break-all" }}>
                  Contract: 8t66M9CNBZttYRJg2kX8b6FoWwPrPSFv9hwL9WJdGec6
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} jc="start" g="10px" p="10px">
                <Paragraph>Follow us:</Paragraph>
                <Link
                  sx={{
                    padding: "5px",
                    display: "grid",
                    minHeight: "30px",
                    minWidth: "30px",
                    placeItems: "center",
                    background: "#19FB9B",
                    boxShadow: " 0px 3px 6px #00000061",
                    borderRadius: "5px",
                  }}
                  href="https://twitter.com/nyraswaponsol"
                  target="blank"
                >
                  <DataImage
                    style={{ filter: "invert(1)" }}
                    src={x}
                    mw="15px"
                  />
                </Link>
                <Link
                  sx={{
                    padding: "5px",
                    display: "grid",
                    minHeight: "30px",
                    minWidth: "30px",
                    placeItems: "center",
                    background: "#19FB9B",
                    boxShadow: " 0px 3px 6px #00000061",
                    borderRadius: "5px",
                  }}
                  href="https://t.me/nyraswaponsol"
                  target="blank"
                >
                  <DataImage
                    style={{ filter: "invert(1)" }}
                    src={tg}
                    mw="20px"
                  />
                </Link>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid item xs={12} sm={7} data-aos="zoom-in">
              <DataImage src={catImage} mw="70%" />
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;

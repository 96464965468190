import { Typography } from "@mui/material";
import React from "react";

const Paragraph = (props) => {
  const { children } = props;
  return (
    <Typography
    variant="body2"
      color="#fff"
      fontFamily="PoppinsR"
      textAlign="left"
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Paragraph;

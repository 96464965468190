import React from "react";
import logo from "../../../../assets/images/logo.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { CustomGrid, DataImage } from "../../../Styles/style";
import CustomButton from "../../CustomBtn";
import Paragraph from "../../Paragraph";
import { Box, Link } from "@mui/material";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xxl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="150px" alt="Logo" />
      </MenuLink>
      <Box sx={{ display: "flex", flexDirection:{xs:"column",sm:"row"},gap:"10px 0", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <Box display="flex" alignItems="center" justifyContent="start" gap="15px" padding="10px">
            <Paragraph>Follow us:</Paragraph>
            <Link
              href="https://twitter.com/nyraswaponsol"
              target="blank"
            >
              <DataImage src={x} mw="15px" />
            </Link>
            <Link
              href="https://t.me/nyraswaponsol"
              target="blank"
            >
              <DataImage src={tg} mw="20px" />
            </Link>
          </Box>
      </Box>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const StyledHeading = styled(Typography)`
  /* padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: 1;
  position: relative; */
`;
const SectionHeading = (props) => {
  const { children } = props;
  return (
    <StyledHeading
    variant="h2"
      color="#fff"
      fontFamily="PoppinsSB"
      textAlign="left"
      {...props}
    >
      {children}
    </StyledHeading>
  );
};

export default SectionHeading;

import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { AbsoluiteImage, CustomGrid, DataImage } from "../../../Styles/style";
import SectionHeading from "../../SectionHeading";
import chart from "../../../../assets/images/chart.png";
import swap from "../../../../assets/images/swap.png";
import catImage from "../../../../assets/images/catImage.png";
import circle from "../../../../assets/images/circle.png";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (min-width: 1440px) {
    min-height: 80vh;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: center;
  padding: 50px 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
  @media (min-width: 1440px) {
    min-height: 80vh;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <CustomGrid container>
            <CustomGrid item xs={12} p="20px 0" data-aos="zoom-in">
              <SectionHeading variant="h4">Earn In Several Ways</SectionHeading>
            </CustomGrid>
            <CustomGrid container item xs={12} sm={6} p="30px" jc="end" data-aos="fade-up">
              <CustomGrid
                item
                xs={12}
                md={8}
                jc="start"
                g="10px"
                p="30px 20px"
                sx={{
                  background: "linear-gradient(to bottom,#9747FD,#4C247F)",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <DataImage src={swap} mw="20px" />
                <Paragraph variant="h6">Swap</Paragraph>
              </CustomGrid>
              <CustomGrid
                item
                xs={12}
                md={8}
                ai="start"
                g="10px"
                p="20px"
                fd="column"
                jc="space-between"
                sx={{
                  background: "#191919",
                  minHeight: "260px",
                  borderRadius: "0 0 5px 5px",
                  boxShadow: " 0px 10px 50px #9d53fe61",
                }}
              >
                <Paragraph>
                  Nyra Swap revolutionizes decentralized exchanges with low-cost
                  stable swaps, offering users a reliable and secure platform
                  for seamless transactions. Built on cutting-edge technology,
                  Nyra Swap provides stability in a volatile market, empowering
                  users with efficient and cost-effective trading experiences.
                </Paragraph>
                <CustomButton
                  disabled
                  sx={{
                    "Mui-disabled": {
                      color: "#3a3a3a !important",
                      border: "none !important",
                    },
                  }}
                >
                  Coming Soon
                </CustomButton>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid container item xs={12} sm={6} p="30px" jc="start" data-aos="fade-up">
              <CustomGrid
                item
                xs={12}
                md={8}
                jc="start"
                g="10px"
                p="30px 20px"
                sx={{
                  background: "linear-gradient(to bottom,#9747FD,#4C247F)",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <DataImage src={chart} mw="20px" />
                <Paragraph variant="h6">$NYRA Token</Paragraph>
              </CustomGrid>
              <CustomGrid
                item
                xs={12}
                md={8}
                ai="start"
                g="10px"
                p="20px"
                fd="column"
                jc="space-between"
                sx={{
                  background: "#191919",
                  minHeight: "260px",
                  borderRadius: "0 0 5px 5px",
                  boxShadow: " 0px 10px 50px #9d53fe61",
                }}
              >
                <Paragraph>
                  $NYRA is the native utility token of Nyra Swap, powering the
                  ecosystem with a deflationary model. Holders enjoy reduced
                  fees, exclusive governance rights, and participate in yield
                  farming, fostering a vibrant and engaged community.
                </Paragraph>
                <CustomButton
                  bgc="linear-gradient(to bottom,#9747FD,#4C247F)"
                  hbgc="transparent"
                  c="#fff"
                  hc="#9747FD"
                  b="1px solid transparent"
                  hb="#9747FD"
                  sx={{
                    boxShadow: "inset 0px 3px 6px #ffffff68",
                  }}
                >
                  Buy Now
                </CustomButton>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>

      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <CustomGrid
            container
            sx={{ position: "relative" }}
          >
            <AbsoluiteImage src={circle} />
            <CustomGrid item xs={12} sm={6} data-aos="zoom-in">
              <DataImage src={catImage} mw="70%" />
            </CustomGrid>
            <CustomGrid item xs={12} sm={6} fd="column" ai="start" data-aos="fade-up">
              <SectionHeading variant="h4">About Nyra Swap</SectionHeading>
              <Paragraph variant="span" padding="20px 0 0">
                Experience stability in decentralized trading with Nyra Swap –
                Redefining transactions through low-cost, secure, and seamless
                swaps.
              </Paragraph>
              <Paragraph variant="h5" padding="20px 0 0">
                Secure Transaction
              </Paragraph>
              <Paragraph>
                Elevate your confidence in every trade with Nyra Swap – Where
                security meets seamless transactions.
              </Paragraph>
              <Paragraph variant="h5" padding="20px 0 0">
                Reliable Support
              </Paragraph>
              <Paragraph>
                Count on Nyra Swap's unwavering support – Your reliable partner
                in navigating the decentralized landscape.
              </Paragraph>
              <Paragraph variant="h5" padding="20px 0 0">
                Easy To Use
              </Paragraph>
              <Paragraph>
                Simplicity redefined with Nyra Swap – Effortless trading for
                both beginners and seasoned enthusiasts.
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;

import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import { Container } from "@mui/material";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "About",
      link: "#about",
    },
    {
      title: "$NYRA",
      link: "#tokenomics",
    },
    {
      title: "Swap",
      link: "#",
    },
    {
      title: "Liquidity",
      link: "#",
    },
  ];
  return (
   
        <Container sx={{py:"10px"}}>
          <DesktopMenu menuList={menuList} />
          <MobileMenu menuList={menuList} />
        </Container>
  );
};

export default LockerMenu;

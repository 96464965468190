import React from "react";
import logo from "../../../../assets/images/logo.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage } from "../../../Styles/style";
import CustomButton from "../../CustomBtn";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xxl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="150px" alt="Logo" />
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <CustomButton>
          Enter App
        </CustomButton>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
